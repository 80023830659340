export const homeAttributeko = {
  name: "심심할 땐 테이크파이브 테스트! | 각종 테스트 모음",
  imageUrl:
    "https://snackcat.click/static/media/Home_main.f1551105af4d7765362e.png",
  desc: "테스트사이트, mbti, 성격유형, 연애, 타로카드, 레벨테스트 등 각종 테스트모음!",
};

export const animal2025Attributeko = {
  name: "2025 동물타로 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/2025Animal_sample.21441278c647ce5ae5d0.png",
  desc: "내 이미지와 비슷한 동물은?",
};

export const coreAttributeko = {
  name: "코어감정 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Core_sample.05fa34a335099a6ba04f.png",
  desc: "나를 이루는 코어감정은?",
};

export const animal2Attributeko = {
  name: "연애 멘탈 테스트 - 동물타로 MBTI 2탄",
  imageUrl:
    "https://snackcat.click/static/media/Animal2_sample.1e2c6a91887fa73e108c.png",
  desc: "동물타로로 알아보는 내 연애 멘탈은?",
};

export const testpingAttributeko = {
  name: "ㅇㅇ핑 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Testping_sample.743de6955051de5b7db6.png",
  desc: "나랑 닮은 ㅇㅇ핑 찾기!",
};

export const inmyheadAttributeko = {
  name: "요즘 내 머릿속 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Inmyhead_sample.14dee1132a397ebf97bd.png",
  desc: "나도 모르는 내 머릿속 TMI는?",
};

export const krcolorAttributeko = {
  name: "나의 전통색 찾기",
  imageUrl:
    "https://snackcat.click/static/media/Krcolor_Sample.f9559be8c6fd26f6ff7d.png",
  desc: "나랑 어울리는 한국 전통색은?",
};

export const sistersAttributeko = {
  name: "자매 유형 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Sisters_sample.442393cb77898bc4e60f.png",
  desc: "우리 자매는 어떤 유형일까?",
};

export const lovemoodAttributeko = {
  name: "연애 분위기 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Lovemood_Sample.3b7414ef3901bc1d8b91.png",
  desc: "나는 연애할 때 어떤 분위기일까?",
};

export const aptitudeAttributeko = {
  name: "직업 적성 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Aptitude_Sample.ae8d3f25a3bb0e315bde.png",
  desc: "내 적성에 맞는 직업은?",
};

export const stabilityAttributeko = {
  name: "연애 안정성 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Stability_Sample.f1a79f6362f7b794f4b3.png",
  desc: "나는 연애에서 안정형일까, 불안형일까?",
};

export const thehouseAttributeko = {
  name: "마음의 집 테스트",
  imageUrl:
    "https://snackcat.click/static/media/TheHouse_Sample.3d7728aefcec3b586ee6.png",
  desc: "내 마음 속의 집은 어떻게 생겼을까?",
};

export const lovethinkAttributeko = {
  name: "ㅇㅇ적 연애 사고 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Lovethink_sample.82d4c7f26fd49db9e51d.png",
  desc: "내 연애 사고는 어떨까?",
};

export const olymposAttributeko = {
  name: "올림포스 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Olympos_sample.ab1f46df0f8918b3fb25.png",
  desc: "나랑 가장 비슷한 올림포스 신은?",
};

export const bigfiveAttributeko = {
  name: "빅파이브 성격유형 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Bigfive_Sample.00cf2a04a0ee94b3713b.png",
  desc: "나의 빅파이브 성격유형은?",
};

export const wordtypeAttributeko = {
  name: "단어 성격유형 검사",
  imageUrl:
    "https://snackcat.click/static/media/Wordtype_Sample.eb7a23fc0fb6027e82cc.png",
  desc: "눈에 띄는 단어 3개로 알아보는 나의 성격은?",
};

export const catlovesmeAttributeko = {
  name: "고양이 애정도 테스트",
  imageUrl:
    "https://snackcat.click/static/media/CatlovesMe_Sample.088a3695d4b60b9cb4ee.png",
  desc: "우리 고양이는 나를 얼마나 좋아할까?",
};

export const lovebalanceAttributeko = {
  name: "연애 밸런스게임",
  imageUrl:
    "https://snackcat.click/static/media/Lovebalance_Sample.7333687113b32c484284.png",
  desc: "A or B! 당신의 선택은?",
};

export const rollingpaper2024loveAttributeko = {
  name: "2024 롤링페이퍼 MBTI - 연애편",
  imageUrl:
    "https://snackcat.click/static/media/RP2024love_Sample.44b582ad41a7c4dd3ab5.png",
  desc: "롤링페이퍼로 본 내 연애스타일은?",
};

export const blackcattarotAttributeko = {
  name: "검은고양이의 조언 타로",
  imageUrl:
    "https://snackcat.click/static/media/Blackcat_Sample.032ac50c98e3e631d0ff.png",
  desc: "걱정, 고민, 선택의 순간에는 검은 고양이의 조언을 들어봐!",
};

export const rollingpaper2024Attributeko = {
  name: "2024 롤링페이퍼 MBTI - 우정편",
  imageUrl:
    "https://snackcat.click/static/media/RP2024_Sample.8733ff39198316f91365.png",
  desc: "내 롤링페이퍼에는 어떤 말이 적혀있을까?",
};

export const pick2024Attributeko = {
  name: "2024 청룡의 해 운세뽑기",
  imageUrl:
    "https://snackcat.click/static/media/Pick2024_sample2.83b4eeae8507abc5e1c6.png",
  desc: "2024년의 내 운세는?🐉🩵",
};

export const animalAttributeko = {
  name: "동물타로 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Animal_sample.1d80dddda499dd75755f.png",
  desc: "내 이미지와 비슷한 동물은?",
};

export const song2000Attributeko = {
  name: "2000년대 감성 테스트 - 노래편",
  imageUrl:
    "https://snackcat.click/static/media/Song2000_Sample.aa0166d6bec5224b6a62.png",
  desc: "그 시절 노래, 얼마나 알고 있어?🎵",
};

export const flirtingAttributeko = {
  name: "플러팅 레벨 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Flirting_Sample.4717b203b624a7329abd.png",
  desc: "나는 플러팅을 얼마나 잘할까?😎",
};

export const keywordMbtiAttributeko = {
  name: "키워드 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Keyword_Sample.c1954c8a9c0a00a95d1d.png",
  desc: "키워드로 보는 내 성격은?",
};

export const realloveAttributeko = {
  name: "찐사랑 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Reallove_Sample.08792855a93510dabe54.png",
  desc: "찐으로 사랑할 때 내 모습은? 💗",
};
export const insomniaAttributeko = {
  name: "통모짜 불면증 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Insomnia_Sample.5ffea2b9beb2222f2409.png",
  desc: "잠들기 어려운 요즘, 혹시 내 성격때문일까?💤",
};

export const playlistAttributeko = {
  name: "플레이리스트 MBTI 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Playlist_Sample.c33a377338ec5b4ce093.png",
  desc: "내 이미지, 특징과 찰떡인 노래는?",
};

export const alcatineAttributeko = {
  name: "알∙카∙틴 중독 테스트",
  imageUrl:
    "https://snackcat.click/static/media/Alcatine_Sample.a765cdabffd196c08a23.png",
  desc: "나는 술, 담배, 커피에 \n 얼마나 진심일까?🤔",
};

export const angelAttributeko = {
  name: "수호천사가 나를 배정 받을 때",
  imageUrl:
    "https://snackcat.click/static/media/Angel_Sample.ff2c996714594c1fc139.png",
  desc: "날 보고 무슨 생각을 했을까?",
};

export const rollingPaper2Attributeko = {
  name: "롤링페이퍼 MBTI 연애편",
  imageUrl:
    "https://snackcat.click/static/media/Rollingpaper2_Sample.8242608db86530759054.png",
  desc: "롤링페이퍼로 본 내 연애스타일은?",
};

export const rollingPaperAttributeko = {
  name: "롤링페이퍼 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Rollingpaeper_Sample.26365e2a228c1076aacb.png",
  desc: "내 롤링페이퍼에는 \n어떤 말이 적혀있을까?",
};

export const nojamlifeAttributeko = {
  name: "인생 노잼력 TEST",
  imageUrl:
    "https://snackcat.click/static/media/Nojam_Sample.79ded0e8b218e8b8721d.png",
  desc: "지금 인생 노잼력은 몇 퍼센트?",
};

export const schoolAttributeko = {
  name: "초딩 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/School_Sample.2759a352fe94e4d74b4c.png",
  desc: "나는 어떤 초딩이었을까?",
};

export const yourmoodAttributeko = {
  name: "당신의 분위기 TEST",
  imageUrl:
    "https://snackcat.click/static/media/Mood_Sample.92657439069e567b32d3.png",
  desc: "남들이 보는 나의 분위기는?",
};

export const zombieAttributeko = {
  name: "좀비월드 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Zombie_Sample.5e651b00b9c6312d264f.png",
  desc: "좀비사태에 대처하는 내 성격은?",
};
