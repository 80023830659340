export const QuestionData = [
  {
    id: 1,
    title: "친구가 번화가에 있는\n타로를 보러 가자고 한다면?",
    answera: "좋아! 지금 같이 가자!",
    answerb: "(사람.. 많겠지?) 어.. 가볼까?",
    type: "EI",
  },
  {
    id: 2,
    title: "나는 확신의...",
    answera: "밖순이",
    answerb: "집순이",
    type: "EI",
  },
  {
    id: 3,
    title: "친구랑 놀고 집에 갈 때 나는...",
    answera: "친구랑 함께 돌아감",
    answerb: "웬만하면 혼자 집에 감",
    type: "EI",
  },
  {
    id: 4,
    title: "타로카드를 볼 때, 나는...",
    answera: "오... 색깔 예쁜데? 맘에 든다.",
    answerb: "무슨 의미일까?",
    type: "SN",
  },
  {
    id: 5,
    title: "내가 더 중요하게 생각하는 것은?",
    answera: "미래보다 현재",
    answerb: "현재보다 미래",
    type: "SN",
  },
  {
    id: 6,
    title: "심오해서 이해하기 어려운\n영화를 본 나는",
    answera: "뭔가 메세지가 있겠거니 \n생각하고 만다",
    answerb: "리뷰와 해석을 \n찾아서 읽어본다",
    type: "SN",
  },
  {
    id: 7,
    title: "친구가 고민을 털어놓을 때 나는...",
    answera: "차분히 들어본 뒤 \n같이 해결하고 싶음",
    answerb: "친구의 고민에 공감하며\n이야기를 들어줌",
    type: "TF",
  },
  {
    id: 8,
    title: "타로 결과가 부정적이라면?",
    answera: "(안 믿음)",
    answerb: "(좋게 나올 떄까지 다시 봄)",
    type: "TF",
  },
  {
    id: 9,
    title: `내가 누군가에게 \n엄청난 리액션을 하고 있다면?`,
    answera: "별 관심없어서 \n 리액션으로 떼우는 중",
    answerb: "관심이 많아서 \n 리액션으로 표현하는 중",
    type: "TF",
  },
  {
    id: 10,
    title: "친구가 늦을 것 같다고 한다면?",
    answera: "얼마나?\n(시간 딱 맞춰서 나감)",
    answerb: "얼마나?\n(대충 좀 늦게 나감)",
    type: "JP",
  },
  {
    id: 11,
    title: "타로 리딩이 예상보다 길어진다면?",
    answera: "계획에 차질이 생길 텐데.. \n약간 스트레스 받음",
    answerb: "예상보다 길어졌지만, \n흥미로우니 괜찮아!",
    type: "JP",
  },
  {
    id: 12,
    title: "타로결과가 \n예상과 다르게 나온다면?",
    answera: "더 나은 계획을 세우기 위해 참고함",
    answerb: "예상 밖이라 오히려 좋아!\n결과를 유연하게 받아들임",
    type: "JP",
  },
];
